import {milesoft} from "@atttomyx/shared-constants";

export const BILLING_URL = "https://billing.milesoft.app";

export const SCHEDULE_NAME = "Schedule";
export const SCHEDULE_TAG_LINE = "Shift management made easy";
export const SCHEDULE_APP_ID_APPLE = "1528232000";
export const SCHEDULE_APP_ID_GOOGLE = "io.milesoft.schedule";
export const SCHEDULE_URL = "https://schedule.milesoft.app";
export const SCHEDULE_VIDEO_ID = "sIryIL9a_4Y";

export const SCHEDULE_SALES_EMAIL_SUBJECT = `${milesoft.COMPANY_NAME} ${SCHEDULE_NAME}`;
export const SCHEDULE_SALES_EMAIL_BODY = `${milesoft.COMPANY_NAME} Sales,\n\nI would like to discuss setting up a ${SCHEDULE_NAME} account.\n\nCompany Name: ???\nMy Name: ???`;

export const SUDOKU_NAME = "Sudoku";
export const SUDOKU_TAG_LINE = "Try to solve Sudoku puzzles faster than your friends";
export const SUDOKU_APP_ID_APPLE = "1539297952";
export const SUDOKU_APP_ID_GOOGLE = "io.milesoft.sudoku";

export const WORDS_NAME = "Words";
export const WORDS_TAG_LINE = "Use logic to guess hidden words";
export const WORDS_APP_ID_APPLE = "1547098934";
export const WORDS_APP_ID_GOOGLE = "io.milesoft.words";

export const DOTS_NAME = "Dots";
export const DOTS_TAG_LINE = "Try to clear the dots faster than your friends";
export const DOTS_APP_ID_APPLE = "1585534737";
export const DOTS_APP_ID_GOOGLE = "io.milesoft.dots";

export const REVEAL_NAME = "Reveal";
export const REVEAL_TAG_LINE = "Wipe away stress and soothe your mind";
export const REVEAL_APP_ID_APPLE = "1585519202";
export const REVEAL_APP_ID_GOOGLE = "io.milesoft.scratch";
export const REVEAL_VIDEO_ID = "Kvp68Q-Vk0k";

export const PAGE_HOME = "/home";
export const PAGE_SUPPORT = "/support";
export const PAGE_SCHEDULE = "/schedule";
export const PAGE_SUDOKU = "/sudoku";
export const PAGE_WORDS = "/words";
export const PAGE_DOTS = "/dots";
export const PAGE_REVEAL = "/reveal";
export const PAGE_USER_TERMS_OF_SERVICE = "/user-terms-of-service";
export const PAGE_ACCEPTABLE_USE_POLICY = "/acceptable-use-policy";
export const PAGE_PRIVACY_POLICY = "/privacy-policy";

export const MODE_INLINE = "inline";
